<template>
  <b-container fluid>
    <b-row>
      <breadcrumb v-if="breadcrumb.length > 0" :breadcrumbList="breadcrumb" class="d-none d-lg-block" :positionAbsolute="false"/>
      <div class="w-100 px-3" v-if="tasksFetched">
        <b-button class="d-sm-none bg-white w-100 btn-filter mb-3" id="filter-button" @click="mobileFilterToggle()">
          {{ $t('filter_results_button') }}<i class="fa-solid fa-sliders ml-2" aria-hidden="true"></i>
        </b-button>
      </div>
    </b-row>
    <b-row v-if="tasksFetched">
      <div class="w-100 d-flex flew-row justify-content-between px-2" v-if="tasks.length > 0">
        <div class="d-flex flew-row align-items-center">
          {{ $t('showing_results', {
          'from': ((currentPage - 1) * tasksPerPage) + 1,
          'to': (currentPage * tasksPerPage) > totalTasks ? totalTasks : (currentPage * tasksPerPage),
          'total': totalTasks
        }) }}
        </div>
        <div class="d-flex flew-row">
          <b-button class=" bg-white w-100 btn-filter mr-3 mb-0 d-none d-sm-block d-lg-none" @click="mobileFilterToggle()">
            {{ $t('filter_results_button') }} <i class="fa-solid fa-sliders ml-2" aria-hidden="true"></i>
          </b-button>
          <b-button class=" btn btn-filter mr-3 mb-0 d-none d-lg-flex align-items-center" v-on:click='filterBarVisible = !filterBarVisible'>
            {{ $t('filter_results_button') }} <i class="fa-solid fa-sliders ml-2" aria-hidden="true"></i>
          </b-button>
          <b-dropdown class="bg-white mb-0 w-100" id="sort-button" variant="primary" right>
            <template slot="button-content">
              <span>{{ $t('tasks_sort_by') }}</span>: <span class="filter-button-text">{{ sortBySelected.text }}</span>
            </template>
            <b-dropdown-item v-for="(option, index) in sortByOptions" class="dropdown-options dropdown-options-font-inherit" :key="index" @click="updateSortBy(option)">{{ option.text }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="px-2 filter-holder" :class="tasksFetched ? 'open' : 'closed'">
        <TaskFilter ref="taskFilter" class="px-lg-2 task-filter " :class="filterBarVisible ? 'open mb-lg-2 mt-lg-3' : 'closed mt-1'"/>
      </div>
      <b-col cols="12" class="sort-and-filter-buttons">
        <div v-if="tasksFetched" class="pb-2">
          <div v-if="tasks.length > 0">
            <b-row class="card-holder pb-3">
              <div class="col-lg-3 col-md-6 col-sm-6 px-sm-2 col-12 card-item my-2" v-for="(task, index) in tasks" :key="task.hash">
                <Card :isFeaturedTask="false" :deliveryTime="task.packages[0].delivery" :taskImage="formatMobileUrl(task.assets[0]['path'], task.assets[0]['type'])" :carouselImage="task" :username="task.user.username" :slug="task.slug"
                      :user_rating="task.user.review_average" :user_count="task.user.review_count" :user_online="task.user.last_active ? isUserOnline(task.user.last_active) : false"
                      :imageIcon="task.user.photo_path" :description="task.title" :rating="task.review_average" :count="task.review_count"
                      :price="settings.service_rate ? task.packages[0].price + (task.packages[0].price * settings.service_rate / 100) : task.packages[0].price"
                      :hash="task.hash" :select="isFavourite(task.hash)" :position="((currentPage - 1) * tasksPerPage) + index + 1" :sellerInfo="task.user"
                      :primaryCategorySlug="task.categories[0].slug" :isHeroTask="task.hero" location="search-results"
                      class="mx-auto ml-sm-0 mr-sm-0 w-100"/>
              </div>
            </b-row>
            <div v-if="totalTasks > tasksPerPage">
              <Pagination :type="'link'" :perPage="tasksPerPage" :currentPage="currentPage" :total="totalTasks" :params="params" v-on:paginationUpdate="updatePagination" class="mt-3"/>
            </div>
          </div>
          <div v-else>
            <b-row>
              <div class="mx-auto no-saved-tasks mt-4">
                <h3 class="font-weight-bold">{{ $t('search_no_tasks_message')}}</h3>
                <p class="pb-0 pl-2 mb-4">{{ $t('search_no_tasks_tips_header') }}</p>
                <div class="d-flex flex-row justify-content-center">
                  <b-button class="bg-white btn-filter mr-2 d-flex align-items-center py-3 px-4 d-lg-none" @click="$refs.taskFilter.displayModal(true)">
                    {{ $t('filter_results_button') }}<i class="fa-solid fa-sliders ml-2" aria-hidden="true"></i>
                  </b-button>
                  <b-button class="bg-white btn-filter ml-2 ml-lg-0 d-flex align-items-center py-3 px-4" @click="searchAll()">
                    {{ $t('search_all') }} <i class="fa-solid fa-magnifying-glass search ml-2"></i>
                  </b-button>
                </div>
              </div>
            </b-row>
          </div>
        </div>
        <div v-else>
          <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"></b-spinner>
          <div class="mb-5">{{ quipText }}</div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ApiService from '../../apiService'
import Card from '../../components/Task/Card'
import Pagination from '../../components/Global/Pagination'
import config from '../../config'
import helper from '../../helper'
import TaskFilter from '../../components/Global/TaskFilter'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import { mapGetters } from 'vuex'
export default {
  name: 'StandardSearch',
  components: {
    Breadcrumb,
    Pagination,
    Card,
    TaskFilter
  },
  created () {
    window.addEventListener('scroll', helper.pauseVideoOnScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', helper.pauseVideoOnScroll)
  },
  metaInfo () {
    return {
      link: [
        {
          rel: 'canonical',
          href: window.location.protocol + '//' + window.location.host + '/' + 'search' + '?per_page=' + this.tasksPerPage + '&page=' + this.currentPage + '&sort=' + this.sortBySelected.value
        }
      ],
      meta: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ]
    }
  },
  data () {
    return {
      settings: JSON.parse(sessionStorage.getItem('settings')),
      quipText: null,
      anyTasksName: config.anytasks_name,
      tasks: [],
      searchTerms: this.getSearchTerms(),
      searchTermsString: this.getSearchTermsString(),
      params: '',
      currentPage: this.getPage(),
      tasksPerPage: this.getTasksPerPage(),
      totalTasks: '',
      tasksFetched: false,
      sortByOptions: this.getSortByOptions(),
      sortBySelected: this.getSortByOptionSelected(),
      hideModal: true,
      breadcrumb: [],
      filterBarVisible: false
    }
  },
  mounted () {
    this.getTasks(false)
  },
  watch: {
    '$route.query': function (to, from) {
      if (Object.keys(from).length > 0 && to !== from) {
        if (!isNaN(this.$route.query.page) && this.$route.query.page.toString() !== this.currentPage.toString()) {
          this.currentPage = this.$route.query.page || 1
        }

        if (this.$route.query.terms !== undefined && this.$route.query.terms !== this.searchTermsString) {
          this.updateSearchTerms()
        }

        if (!isNaN(this.$route.query.sort) && this.$route.query.sort !== this.sortBySelected.value) {
          this.sortBySelected = this.getSortByOptionSelected()
        }

        this.getTasks(false)
      } else if (this.$route.query.sort === 'recommended') {
        this.updateSortBy({ id: 4, text: this.$t('tasks_sort_best_popular'), value: 'popular' })
      }
    }
  },
  methods: {
    formatMobileUrl (filename, filetype) {
      return helper.formatMobileUrl(filename, filetype)
    },
    updateSearchTerms () {
      if (this.$route.query.terms !== '') {
        this.searchTerms = this.getSearchTerms()
        this.searchTermsString = this.getSearchTermsString()
      } else {
        this.searchTerms = []
        this.searchTermsString = ''
      }
      this.currentPage = 1
    },
    filterDetail () {
      return this.$refs.taskFilter.getData(true, false)
    },
    getAdvanceSearchDetails (routeQuery) {
      let advanceSearch = {}
      if (routeQuery.categories !== undefined) {
        advanceSearch['categories'] = routeQuery.categories
      }

      if (routeQuery['seller_location[]'] !== undefined) {
        advanceSearch['seller_location[]'] = routeQuery['seller_location[]']
      }

      if (routeQuery.with_balance !== undefined) {
        advanceSearch['with_balance'] = routeQuery.with_balance
      }

      if (routeQuery.languages !== undefined) {
        advanceSearch['languages'] = routeQuery.languages
      }

      if (routeQuery['exact_words[]'] !== undefined) {
        advanceSearch['exact_words[]'] = routeQuery['exact_words[]']
      }

      return advanceSearch
    },
    getTasks (updateHistory = true) {
      this.quipText = helper.loadQuip(null)
      this.tasksFetched = false

      window.scrollTo(0, 0)
      let filters = this.filterDetail()
      let advanceSearch = this.getAdvanceSearchDetails(this.$route.query)
      let urlParams = ApiService.buildGetTaskParameters(this.tasksPerPage, this.currentPage, this.searchTerms, filters.deliveryTime, filters.min, filters.max, filters.rating, filters.languages, [], this.sortBySelected.value, advanceSearch)

      if (this.$route.query.gclid) {
        urlParams += '&gclid=' + this.$route.query.gclid
      }
      if (this.$route.query.utm_source) {
        urlParams += '&utm_source=' + this.$route.query.utm_source
      }
      if (this.$route.query.utm_medium) {
        urlParams += '&utm_medium=' + this.$route.query.utm_medium
      }
      if (this.$route.query.utm_campaign) {
        urlParams += '&utm_campaign=' + this.$route.query.utm_campaign
      }

      this.params = urlParams

      if (this.$route.query.sort === 'recommended') {
        updateHistory = true
      }
      if (updateHistory) {
        history.pushState({}, null, urlParams)
      }

      return ApiService.getTasksWithPrebuildParams(urlParams).then(response => {
        this.tasksFetched = true
        this.totalTasks = response.data.data.pagination.total
        this.tasks = response.data.data.results
        if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'event': 'search',
            'search_terms': this.getSearchTermsString(),
            'numberOfResults': this.totalTasks
          })
        }
        this.breadcrumb = [
          { 'name': this.anyTasksName, 'link': this.$router.resolve({ name: 'home' }).href },
          { 'name': this.$t('search_results'), 'link': this.$router.resolve({ name: 'search', query: this.$route.query }).href }
        ]
      }).catch((error) => {
        this.tasksFetched = true
        ApiService.showError(error)
      })
    },
    updatePagination (page) {
      this.currentPage = page
      if (this.$route.query.page !== this.currentPage) {
        this.$route.query.page = this.currentPage
        this.getTasks()
      }
    },
    updateSortBy (item) {
      this.sortBySelected = item
      let params = this.$route.query
      let formData = {
        sort: item.value,
        page: 1
      }
      this.$router.push({ query: Object.fromEntries(Object.entries({ ...params, ...formData })) })
    },
    resetFilters () {
      this.$refs.taskFilter.clear('all')
      this.getTasks()
    },
    searchAll () {
      this.$children[0].$children[3].$children[0].clearAll()
      this.$router.push('/search?terms=')
      this.$refs.taskFilter.clear('all')
      this.getTasks()
    },
    getPage () {
      if (this.$route.query.page && !isNaN(this.$route.query.page)) {
        return this.$route.query.page
      } else {
        return 1
      }
    },
    getTasksPerPage () {
      if (this.$route.query.per_page && !isNaN(this.$route.query.per_page)) {
        return this.$route.query.per_page
      } else {
        return config.task_search_results_amount_to_show_default
      }
    },
    getSortByOptionSelected () {
      let params = this.$route.query
      if (params.sort) {
        for (let item of this.getSortByOptions()) {
          if (item.value === params.sort) {
            return item
          }
        }
      }
      return { id: 4, text: this.$t('tasks_sort_best_popular'), value: 'popular' }
    },
    getSortByOptions () {
      let sort = [
        { id: 0, text: this.$t('tasks_sort_newest'), value: 'date.desc' },
        { id: 1, text: this.$t('tasks_sort_lowest_price'), value: 'price.asc' },
        { id: 2, text: this.$t('tasks_sort_highest_price'), value: 'price.desc' },
        { id: 3, text: this.$t('tasks_sort_best_reviewed'), value: 'review.desc' },
        { id: 4, text: this.$t('tasks_sort_best_popular'), value: 'popular' },
        { id: 5, text: this.$t('tasks_sort_last_active'), value: 'lastActive' }
      ]

      if (this.$route.query.terms) {
        sort.push({ id: 5, text: this.$t('tasks_sort_relevance'), value: 'relevance.desc' })
      }

      return sort
    },
    getSearchTerms () {
      if (this.$route.query.terms) {
        const el = document.getElementById('nav-search')
        if (el) {
          el.value = this.$route.query.terms
        }
        return this.$route.query.terms.split(' ')
      } else {
        return []
      }
    },
    getSearchTermsString () {
      return this.getSearchTerms().join(' ')
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    },
    mobileFilterToggle () {
      this.$refs.taskFilter.displayModal(true)
      this.filterBarVisible = true
    }
  },
  computed: {
    ...mapGetters(['isFavourite']),
    config () {
      return config
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .btn:focus {
  box-shadow: none !important;
}

.filter-holder {
  &.closed {
    overflow: hidden;
    height: 0;
    margin: 0;
    padding: 0;
  }

  &.overflow {
    overflow: auto;
    height: auto;
  }
}

.task-filter {
  opacity: 0;
  animation: none;

  &.open {
    opacity: 1;
    transition: all .25s;
    animation: fadeIn .25s ease-in-out;
  }

  &.closed {
    margin-top: -25px;
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    transition: all .25s;
    animation: fadeOut .12s ease-in-out;
    z-index: 0;
  }
}

@include lg-up {
  .task-filter {
    &.open {
      max-height: 242px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.search {
  cursor: pointer;
}

.search:hover {
  color: #44aae1 !important;
  opacity: 1 !important;
}

@include sm-up {
  #filter-button,
  #sort-button {
    width: auto !important;
  }
}

.btn-filter {
  background: white;
  border: 1px solid $task-grey-border;
  color: $brand-denim;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

</style>

<style>
.dropdown-menu {
  z-index: 5 !important;
}

@media (max-width: 991px) {
  .hideSidebar {
    display: none;
  }

}
</style>
