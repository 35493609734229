<template>
  <div class="task-results mt-3">
    <AdvancedSearch v-if="getAdvanceSearch().top" />
    <StandardSearch v-else />
  </div>
</template>

<script>
import StandardSearch from '../../components/StandardSearch/StandardSearch'
import AdvancedSearch from '../../components/AdvancedSearch/AdvancedSearch'
import config from '../../config'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TaskResults',
  components: {
    AdvancedSearch,
    StandardSearch
  },
  data () {
    return {
      tracking_switch: config.tracking_enabled === 'true'
    }
  },
  mounted () {
    if (this.getAdvanceSearch().top && localStorage.getItem('accessToken')) {
      this.processJwt().then(() => {
        window.aa('setUserToken', this.$store.state.user.hash)
      })
    }
  },
  methods: {
    ...mapActions([
      'processJwt'
    ])
  },
  computed: {
    ...mapGetters(['getAdvanceSearch'])
  }
}
</script>
